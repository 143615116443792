import './bootstrap';
import md5 from 'md5';
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://9ec445b391bd633263c49ff6853eec94@sentry.pnp.digital/24",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

$(function() {
    // update form delete buttons so the hover text says archive instead of delete
    const formDeleteButtons = $(".btn-delete.form-btn.ux-delete")
    if (formDeleteButtons.length > 0) {
        for (let i = 0; i < formDeleteButtons.length; ++i) {
            const delBtn = $(formDeleteButtons[i]);
            if (delBtn.attr("title")) {
                delBtn.attr("title", delBtn.attr("title").replace("Delete", "Archive"));
            }

            if (delBtn.attr("aria-label")) {
                delBtn.attr("aria-label", delBtn.attr("aria-label").replace("Delete", "Archive"));
            }

            if (delBtn.attr("data-bs-original-title")) {
                delBtn.attr("data-bs-original-title", delBtn.attr("data-bs-original-title").replace("Delete", "Archive"));
            }

            // change the bin icon to be the word archive since we need the bin for perm delete
            delBtn.html('Archive');
        }
    }

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')),
        tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    $('.checkbox-container input').on('change', function() {
        $(this).parent().toggleClass('checked');
    });

    $('#sidebar-toggle').on('click', function() {
        $('.sidebar-dropdown').slideToggle();
    });

    $('input[type="range"]').on('input change', function() {
        $(this).val(this.value);
        $(this).attr("value", this.value);
    });

    document.documentElement.setAttribute('data-theme', 'light');

    const listPages = [
        "hazard", "cause", "control", "user", "client", "project", "basecontroltype", "functionalarea", "video"
    ];

    /* At request of Adrian, change "Is Deleted" label in filters to "Show Archived" */
    listPages.forEach((id) => {
        $(`label[for='${id}-${md5("deleted_at")}']`).html("Search Archive Only");
    });

    $(`.btn-add:contains("Add Audit Log")`).remove();

    setTimeout(() => {
        // Default page size: 100 for System control type list
        $("select[name='index-BaseControlType_length']").val("100").trigger("change");
    }, 250);
});

window.renderListRowHazard = (row, data, dataIndex) => {
    /* Highlight outcome and highlight description hidden columns */
    if (data[9] || data[10]) {
        $(row).addClass('highlight');
    }
}

window.renderListRowCause = (row, data, dataIndex) => {
    if (data[10]) {
        $(row).addClass('highlight');
    }
}

window.renderListRowControl = (row, data, dataIndex) => {
    if (data[12]) {
        $(row).addClass('highlight');
    }
}

window.checkIfFormIsDirty = function (formObject, originalFormData) {
    // unable to check if form is dirty as we lack the data we need
    if (typeof originalFormData !== "string" || originalFormData === '') {
        return null;
    }

    // unable to check if form is dirty as we did not receive the form object
    if (typeof formObject !== "object" && formObject.length <= 0) {
        return null;
    }

    // check if any file inputs have been altered (does not serialize in a way that is useful so must do this separate)
    let filesChanged = false;
    formObject.find("input[type=file]").each(function (index, input) {
        // file list should be greater than 0 if a file has been changed/added
        // should the file input be disabled this means the user has clicked the delete button to remove the image
        if (input.files.length > 0 || input.disabled) {
            filesChanged = true;
        }
    });

    // user has changed selected files or changed any other fields
    return filesChanged || originalFormData !== formObject.serialize();
}
